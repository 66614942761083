/* src/ComingSoon.css */
.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    /* background-color: #f5f5f5; */
    background-color: #ffff;
  }
  
  .coming-soon-logo {
    max-width: 80%;
    width: 2000px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2em;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .coming-soon-logo {
      width: 300px;
    }
  
    h1 {
      font-size: 1.5em;
    }
  }
  